@font-face {
  font-family: "Futura-Light";
  src: url("./assets/fonts/Futura-Light.eot");
  src: url("./assets/fonts/Futura-Light.woff") format("woff"),
    url("./assets/fonts/Futura-Light.ttf") format("ttf");
}

@font-face {
  font-family: "Futura-Medium";
  src: url("./assets/fonts/Futura-Medium.eot");
  src: url("./assets/fonts/Futura-Medium.woff") format("woff"),
    url("./assets/fonts/Futura-Medium.ttf") format("ttf");
}
@font-face {
  font-family: "Futura-Demi";
  src: url("./assets/fonts/Futura-Demi.eot");
  src: url("./assets/fonts/Futura-Demi.woff") format("woff"),
    url("./assets/fonts/Futura-Demi.ttf") format("ttf");
}
@font-face {
  font-family: "Futura-Book";
  src: url("./assets/fonts/Futura-Book.eot");
  src: url("./assets/fonts/Futura-Book.woff") format("woff"),
    url("./assets/fonts/Futura-Book.ttf") format("ttf");
}
@font-face {
  font-family: "FenomenSans-Thin";
  src: url("./assets/fonts/FenomenSans-Thin.eot");
  src: url("./assets/fonts/FenomenSans-Thin.woff") format("woff"),
    url("./assets/fonts/FenomenSans-Thin.ttf") format("ttf");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
}
