.App {
  height: 100%;
  position: relative;
}

/* CUSTOM IMG LIST CSS */
ul.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.images > li {
  position: relative;
  padding-top: 66%;
}

ul.images > li img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* CLOSE BUTTON PANORAMA  */
/* .fullscreen-exit-btn {
  z-index: 100000 !important;
} */
/* button.fullscreen-exit-btn {
  border: none;
  background: none;
  position: fixed;
  top: 20px !important;
  right: 0;
  padding: 20px;
  -webkit-appearance: none;
  cursor: pointer;
  z-index: 10;
} */
